<template>
  <v-container fill-height>
    <!-- <v-dialog scrollable  v-model="showDialog" width="60%">
            <v-card max-height="600">
                <v-card-title style="line-height:150%" class="grey lighten-1"> {{ $t('login.tnc.title') }}</v-card-title>
                <v-card-text>
                    <span v-html="$t('login.tnc.content')"></span>
                </v-card-text>
            </v-card>
        </v-dialog> -->

    <v-card
      :loading="logging_in"
      class="mx-auto my-12"
      max-width="550"
      min-width="550"
    >
      <template slot="progress">
        <v-progress-linear
          height="5"
          indeterminate
        />
      </template>
      <v-card-title
        data-cy="first-page-title"
      >
        {{ $t('login.welcome') }}
      </v-card-title>
      <v-card-subtitle>{{ $t('login.subtitle') }}</v-card-subtitle>
      <v-form @submit.prevent="onSubmit">
        <v-card-text>
          <!-- <v-alert dense outlined type="error" :value="alert">
                        {{ $t('login.error.failed') }}
                    </v-alert> -->
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                v-model="form.organization"
                :label="$t('Organization')"
                :error="$v.form.organization.$error"
                :error-messages="$v.form.organization.$error ? (!$v.form.organization.required ? $t('validate.required', { field: $t('Organization') }) : '') : ''"
                data-cy="org_textfield"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                v-model="form.username"
                :label="$t('Username')"
                :error="$v.form.username.$error"
                :error-messages="$v.form.username.$error ? (!$v.form.username.required ? $t('validate.required', { field: $t('Username') }) : '') : ''"
                data-cy="username_textfield"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                v-model="form.password"
                :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pw ? 'text' : 'password'"
                :label="$t('Password')"
                @click:append="show_pw = !show_pw"
                :error="$v.form.password.$error"
                :error-messages="$v.form.password.$error ? (!$v.form.password.required ? $t('validate.required', { field: $t('Password') }) : '' ) : ''"
                data-cy="pswd_textfield"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-checkbox
                v-model="form.isAccepted"
                class="mt-0"
                :error-messages="(!form.isAccepted && $v.form.isAccepted.$dirty) ? $t('validate.accept_term') : ''"
                data-cy="tnc_checkbox"
              >
                <template v-slot:label>
                  {{ $t('login.tnc.statementPrefix') }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip_on }">
                      <v-dialog
                        scrollable
                        width="60%"
                      >
                        <template v-slot:activator="{ on: dialog_on }">
                          <a
                            target="_blank"
                            @click.prevent
                            v-on="{...tooltip_on, ...dialog_on}"
                            v-html="$sanitize($t('login.tnc.hyperText') )"
                            data-cy="tnc-link"
                          />
                        </template>
                        <v-card
                          max-height="600"
                          data-cy="tnc"
                        >
                          <v-card-title
                            style="line-height:150%"
                            class="grey lighten-1"
                            data-cy="tnc_title"
                          >
                            {{ $t('login.tnc.title') }}
                          </v-card-title>
                          <v-card-text data-cy="tnc_content">
                            <TextareaSafeDisplay :value="$t('login.tnc.content')" />
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </template>
                    {{ $t('login.tnc.title') }}
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn
                class="primary ma-2"
                type="submit"
                :loading="logging_in"
                data-cy="login_btn"
              >
                {{ $t('login.buttons.sign_in') }}
              </v-btn>
              <v-btn
                class="ma-2"
                :to="{ name: 'ForgotPassword' }"
                data-cy="forgot_pw_btn"
              >
                {{ $t('login.buttons.forgot_pw') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <!-- <v-card-actions class="text-center">
            </v-card-actions> -->
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'

Vue.use(Vuelidate)

export default {
  components: {
    TextareaSafeDisplay
  },
  watch: {
    is_logged_in () {
      this.redirectIfNeeded()
    }
    // showDialog(_is_show_dialog) {
    //     if (_is_show_dialog) {
    //         this.form.isAccepted = !this.form.isAccepted;
    //     };
    // }
  },
  computed: {
    ...mapGetters('auth', [
      'is_logged_in'
    ]),
    ...mapState('auth', [
      'logging_in'
    ])
  },
  data: () => ({
    form: {
      organization: '',
      username: '',
      password: '',
      isAccepted: false
    },
    show_pw: false,
    alert: false
    // showDialog: false,
  }),
  validations () {
    return {
      form: {
        organization: {
          required
        },
        username: {
          required
        },
        password: {
          required
        },
        isAccepted: {
          required
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    onSubmit () {
      // Trim username & organization
      this.form.organization = this.form.organization.trim()
      this.form.username = this.form.username.trim()
      this.$v.form.$touch()

      if (this.$v.form.$invalid || !this.form.isAccepted) {
        return
      }

      this.login(this.form).then(() => {
        this.redirectIfNeeded()
      })
        .catch((error) => {
          // console.log(JSON.stringify(error.response, 2));
          if (error && error.response && error.response.status === 429) {
            this.$notify({
              text: this.$t('login.error.tooMany'),
              type: 'error'
            })
          } else {
            this.$notify({
              text: this.$t('login.error.failed'),
              type: 'error'
            })
          }
        })
    },
    redirectIfNeeded () {
      if (this.is_logged_in && this.$route.name !== 'Dashboard') {
        this.$router.replace({ name: 'Dashboard' })
          .catch(err => {
            console.log(err)
          })
      }
    }
  },
  mounted () {
    this.redirectIfNeeded()
  }
}
</script>

<style>

</style>
